import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen }) {
    const [checked, setChecked] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        if (checked) {
            localStorage.setItem('notification', dayjs().add(24, 'hours'))
        }
    };


    useEffect(() => {
        const lastChecked = localStorage.getItem('notification');
        if (!lastChecked || dayjs(lastChecked).isBefore(dayjs())) {
            setOpen(true)
        }
        else {
            setChecked(true)
        }
    }, [])
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: { backgroundColor: 'rgb(217, 217, 217)' } // Đổi màu nền popup
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>{"Important Notice"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ fontSize: '14px' }}>
                        <i>

                            <i>February 6, 2025</i> <br />
                            Dear NOW Chain Testnet Users, <br />
                            We are announcing the phased discontinuation of all activities on the NOW Chain (testnet). As of today, all new staking on the testnet have been xstopped. This strategic decision allows our team to concentrate on enhancing the features, security, and performance of mobiNODE and the NOW Chain’s ecosystem. <br />
                            <br /> <b> Details:</b> <br />
                            <b>Reward Swaps:</b>  Any rewards or benefits earned on the testnet will be swapped to NOW Coin on a weekly basis, in accordance with the terms and conditions outlined in our policies. <br />
                            <b>Using NOW Coin:</b>  Once swapped, you can transfer your NOW Coin to trade on the MEXC exchange or stake it again within the mobiNODE ecosystem for additional benefits. <br />
                            <b>Next Steps:</b>  To proceed with the reward swap process, please complete the required form: <a target='_blank' href="https://forms.gle/bXPnT1k7nmUwWe4W8">https://forms.gle/bXPnT1k7nmUwWe4W8</a> <br />
                            We sincerely thank you for your participation and feedback during the testnet phase. Your support has been invaluable in shaping the future of NOW Chain. We are excited to bring you improved services and features on the mainnet. <br />
                            If you have any questions or need assistance during this transition, please feel free to contact our support team at <a href="mailto:support@nowchain.co">support@nowchain.co</a>  <br />
                            Thank you for your understanding and continued support. <br />
                            Best regards, <br /> <br />
                            <b> NOW Chain Team</b>
                        </i>

                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', }}>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }} onClick={() => setChecked(!checked)}>
                        <Checkbox checked={checked} /><span style={{ cursor: 'pointer' }}>hide notification for 24h</span>
                    </div>
                    <Button onClick={handleClose}><b>CLose</b></Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
