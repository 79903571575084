/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import ButtonCustom from "components/Atoms/Button";
import { FormStyles } from "./styles";
import imgFromSwap from "assets/img/form-swap.png";
import imgSwap from "assets/img/icon-swap.png";
import InputBase from "components/Atoms/InputBase";
import { useEffect, useState } from "react";
import { formatDecimal18, formatNumber } from "utils/common";
import { toastMessage } from "utils/toastMessage";
import { MESSAGE } from "constants/message";
import { useDispatch, useSelector } from "react-redux";
import { masterSelector } from "redux/slice/masterSlice";
import { userSelector } from "redux/slice/userSlice";
import { connectWallet } from "utils/connectWallet";
import { SETTING } from "constants/config";
import { USDT_ABI } from "abi/USDT";
import { useSearchParams } from "react-router-dom";
import { ModalChooseCoin } from "../ModalChooseCoin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "@mui/material";
import { switchNetwork, transferBNB, transferToken } from "utils/web3";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { createSwap } from "services/swap";
import { NOW_ABI } from "abi/NOW";

export default function FromSwap({ setOpen }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { receiveAddress, listCoin } = useSelector(masterSelector);
  const { userInfo } = useSelector(userSelector);
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, chainId } = useWeb3ModalAccount();

  const [coinFrom, setCoinFrom] = useState(null);
  const [coinTo, setCoinTo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpposite, setIsOpposite] = useState(false);

  // HANDLE CHANGE AMOUNT
  const handleChangeAmount = (value) => {
    setCoinFrom({ ...coinFrom, amount: value });
  };


  // HANDLE SEND
  const handleSend = async () => {
    try {
      const addressAdmin = getWalletReceive(coinFrom?.symbol);
      let hash = "";
      switch (coinFrom?.symbol) {
        case "BNB":
          if (chainId !== 56) {
            toastMessage('Please switch to the NOW Chain network!', MESSAGE.ERROR);
            setLoading(false);
            return;
          }
          hash = await transferBNB(
            walletProvider,
            addressAdmin,
            formatDecimal18(coinFrom?.amount).toString()
          );
          break;

        case "USDT":
          if (chainId !== 56) {
            toastMessage('Please switch to the NOW Chain network!', MESSAGE.ERROR);
            setLoading(false);
            return;
          }
          hash = await transferToken(
            walletProvider,
            SETTING.CONTRACT_USDT,
            USDT_ABI,
            addressAdmin,
            formatDecimal18(coinFrom?.amount).toString()
          );
          break;
        case "NOW":
          if (chainId !== 2014) {
            toastMessage('Please switch to the NOW Chain network!', MESSAGE.ERROR);
            setLoading(false);
            break;
          }
          hash = await transferToken(
            walletProvider,
            SETTING.CONTRACT_NOW,
            NOW_ABI,
            addressAdmin,
            formatDecimal18(coinFrom?.amount).toString()
          );
          break;
      }
      createSwap(dispatch, {
        from: coinFrom?.symbol,
        to: coinTo?.symbol,
        amount: coinFrom?.amount,
        hash,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // HADNLE SWAP
  const handleSwap = async () => {
    setOpen(true)
    // setLoading(true);
    // if (!userInfo) await connectWallet(dispatch, userInfo, searchParams);
    // else {
    //   if (coinFrom && coinFrom.amount > 0) {
    //     handleSend();
    //   } else {
    //     setLoading(false);
    //     toastMessage("Please enter quantity!", MESSAGE.ERROR);
    //   }
    // }
  };

  // HANDLE CHANGE COIN
  const handleChangeCoin = async () => {
    const temp = coinTo;
    setCoinTo({ ...coinFrom, amount: coinTo?.amount })
    setCoinFrom({ ...temp, amount: coinFrom?.amount });
    if (isOpposite) {
      await switchNetwork({ walletProvider, chainID: 2014, chainName: 'NOW Chain', rpcUrl: 'https://rpc-testnet.nowscan.io', symbol: 'NOW', decimals: 18, explorerUrl: 'https://nowscan.io' })
    }
    else {
      await switchNetwork({ walletProvider, chainID: 56, chainName: 'Binance Smart Chain', rpcUrl: 'https://bsc-dataseed.binance.org', symbol: 'BNB', decimals: 18, explorerUrl: 'https://bscscan.com' })
    }
  }

  useEffect(() => {
    if (coinFrom && coinTo) {
      if (coinFrom?.symbol !== 'NOW') setIsOpposite(true)
      else setIsOpposite(false)
      setCoinTo({
        ...coinTo,
        amount: (coinFrom?.amount * coinFrom?.price) / coinTo?.price,
      });
    }
  }, [coinFrom]);

  useEffect(() => {
    if (listCoin?.length > 0) {
      setCoinTo({
        ...listCoin?.filter((item) => item.symbol === "NOW")[0],
        amount: 0,
      });
      setCoinFrom({ ...listCoin?.filter((item) => item.swap)[0], amount: 0 });
    }
  }, [listCoin]);

  const getWalletReceive = (symbol) => {
    let result = null;
    switch (symbol) {
      case "BNB":
        result = receiveAddress?.filter(
          (item) => item.name === "receive_bnb_wallet"
        );
        break;

      case "USDT":
        result = receiveAddress?.filter(
          (item) => item.name === "receive_usdt_wallet"
        );
        break;
      case "NOW":
        result = receiveAddress?.filter(
          (item) => item.name === "receive_now_wallet"
        );
        break;
    }
    return result[0]?.value;
  };
  return (
    <FormStyles.Main>
      <ModalChooseCoin
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onChange={(value) =>
          !isOpposite ? setCoinTo({ ...value, amount: (coinFrom?.amount * coinFrom?.price) / value?.price }) :
            setCoinFrom({ ...value, amount: coinFrom?.amount })
        }
        defaultValue={!isOpposite ? coinTo : coinFrom}
        options={listCoin?.filter((item) => item.swap)}
      />
      <FormStyles.Head>
        <div>
          <FormStyles.Title>Swap NOW</FormStyles.Title>
          <FormStyles.SubTitle>Trade tokens in an instant</FormStyles.SubTitle>
        </div>
        <img src={imgFromSwap} alt="NOW" />
      </FormStyles.Head>
      {coinFrom ? (
        <FormStyles.CoinInfo style={{ cursor: isOpposite ? 'pointer' : "default" }} onClick={() => isOpposite && setOpenModal(true)}>
          <img
            src={SETTING.DOMAIN_API + "/" + coinFrom?.image}
            alt={coinFrom?.name}
          />
          <p>{coinFrom?.symbol}</p>
          {coinFrom?.symbol !== 'NOW' && <ExpandMoreIcon />}
        </FormStyles.CoinInfo>
      ) : (
        <CircularProgress color="secondary" size="1.5rem" />
      )}

      <InputBase
        onChange={(e) => handleChangeAmount(e.target.value)}
        placeholder="0"
        name="from"
        type="number"
      />
      <FormStyles.IconSwap src={imgSwap} alt="SWAP" onClick={handleChangeCoin} />
      {coinTo ? (
        <FormStyles.CoinInfo style={{ cursor: !isOpposite ? 'pointer' : "default" }} onClick={() => !isOpposite && setOpenModal(true)}>
          <img
            src={SETTING.DOMAIN_API + "/" + coinTo?.image}
            alt={coinTo?.name}
          />
          <p>{coinTo?.symbol}</p>
          {coinTo?.symbol !== 'NOW' && <ExpandMoreIcon />}
        </FormStyles.CoinInfo>
      ) : (
        <CircularProgress color="secondary" size="1.5rem" />
      )}

      <InputBase
        onChange={() => { }}
        value={formatNumber(coinTo?.amount)}
        placeholder="0"
        name="to"
        disabled
      />

      {isConnected ? (
        <ButtonCustom onClick={handleSwap} >
          {loading ? (
            <CircularProgress color="secondary" size="1.5rem" />
          ) : (
            "SWAP"
          )}
        </ButtonCustom>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <w3m-button />
        </div>
      )}
    </FormStyles.Main>
  );
}
